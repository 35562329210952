import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

import styles from "./sass/Policy.module.sass";

export default class Policy extends Component {
    constructor(props: {}) {
        super(props);
        document.title = "Omega — Policy";
    }

    render() {
        return (
            <div className="content">
                <div className={styles.legal}>
                    <h2 className={styles.title}>
                        <FormattedMessage
                            id="policy.title"
                            defaultMessage="Protection and processing of personal data"
                        />
                    </h2>
                    <h3 className={styles.subtitle}>
                        <FormattedMessage
                            id="policy.compliance.title"
                            defaultMessage="Compliance with applicable laws"
                        />
                    </h3>
                    <p className={styles.paragraph}>
                        <FormattedMessage
                            id="policy.compliance.description"
                            defaultMessage="The site {url} respects the privacy of the user and strictly complies with the laws in force on the protection of privacy and individual freedoms. No personal information is collected without your knowledge. No personal information is disclosed to third parties."
                            values={{
                                url: (
                                    <a href="https://getomega.dev/">
                                        https://getomega.dev/
                                    </a>
                                ),
                            }}
                        />
                    </p>
                    <h3 className={styles.subtitle}>
                        <FormattedMessage
                            id="policy.cookies.title"
                            defaultMessage="Cookies"
                        />
                    </h3>
                    <p className={styles.paragraph}>
                        <FormattedMessage
                            id="policy.cookies.description"
                            defaultMessage="When consulting {url}, cookies are placed on your computer, mobile or tablet. Only cookies intended for audience measurement are used. These cookies do not collect personal data. Audience measurement tools are deployed to obtain information on visitor navigation. They allow in particular to understand how users arrive on a site and to reconstruct their journey."
                            values={{
                                url: (
                                    <a href="https://getomega.dev/">
                                        https://getomega.dev/
                                    </a>
                                ),
                            }}
                        />
                    </p>
                    <p className={styles.paragraph}>
                        <FormattedMessage
                            id="policy.cookies.description2"
                            defaultMessage="The data generated by cookies is transmitted and stored by audience measurement providers (Google Analytics). Audience measurement providers may communicate this data to third parties in the event of a legal obligation or when these third parties process this data on their behalf."
                        />
                        {/* <a href="javascript:void(0)" id="deny-cookie">Click here to refuse cookies.</a> */}
                    </p>
                    <p className={styles.paragraph}>
                        <FormattedMessage
                            id="policy.cookies.description3"
                            defaultMessage="Learn more about cookies at {url}."
                            values={{
                                url: (
                                    <a href="https://www.cookiesandyou.com">
                                        https://www.cookiesandyou.com
                                    </a>
                                ),
                            }}
                        />
                    </p>
                </div>
            </div>
        );
    }
}
